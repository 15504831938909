<template>
  <div v-loading="loading">
    <div ref="onlyRead" class="only-read" v-if="onlyRead" @click="handleClick"></div>
    <div ref="markdownEditor" class="editor" v-else></div>
  </div>
</template>

<script>
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import Vditor from 'vditor';
  import { uploadFile } from '@/utils';
  import _ from 'lodash';

  const toolbar = [
    'headings',
    'bold',
    'italic',
    'strike',
    'link',
    '|',
    'list',
    'ordered-list',
    'check',
    '|',
    'quote',
    'line',
    'code',
    'inline-code',
    '|',
    'upload',
    'table',
    '|',
    'undo',
    'redo',
    '|',
    'fullscreen',
    'edit-mode',
  ];

  @Component
  export default class MarkdownEditor extends Vue {
    @Prop({ type: String, default: '' }) value
    @Prop(Boolean) onlyRead

    loading = true

    @Watch('value', { immediate: true })
    async valueChange(val) {
      if (this.$vditor) {
        this.$vditor.setValue(val || '');
      } else if (this.onlyRead) {
        this.initPreview(val);
      }
    }

    async mounted() {
      if (this.onlyRead) {
        await this.initPreview();
      } else {
        await this.initVditor();
        this.valueChange(this.value);
      }
      this.loading = false;
    }

    async initPreview(val) {
      const value = val || this.value || '';
      if (!this.onlyRead) {
        return;
      }
      await this.$nextTick();
      await this.$vditorPreviewPromise;
      this.$vditorPreviewPromise = new Promise(resolve => {
        Promise.resolve(this.$vditorPreviewPromise).then(() => {
          Vditor.preview(this.$refs.onlyRead, value, {
            markdown: {
              toc: true,
              autoSpace: true,
            },
            anchor: 2,
            after: () => {
              this.$images = _.map(this.$refs.onlyRead.querySelectorAll('img'), img => img.src);
              resolve();
              this.initAnchorPoint();
            },
          });
        });
      });
      return this.$vditorPreviewPromise;
    }

    // 定位到锚点
    initAnchorPoint() {
      if (this.$route.hash) {
        const dom = document.querySelector(decodeURI(this.$route.hash));
        document.documentElement.scrollTop = _.get(dom, 'offsetTop', 0);
      }
    }

    handleClick(e) {
      const src = _.get(e.target, 'src');
      if (src) {
        const index = this.$images.indexOf(src);
        const images = index === -1 ? [src] : this.$images;
        this.$previewImage(images, index === -1 ? 0 : index);
      }
    }

    initVditor() {
      return new Promise((resolve) => {
        const vditor = new Vditor(this.$refs.markdownEditor, {
          toolbar,
          preview: { mode: 'editor' },
          toolbarConfig: {
            hide: false,
            pin: true,
          },
          counter: {
            enable: true,
            type: 'markdown',
          },
          cache: 'markdownEditor',
          upload: {
            headers: {
              Authorization: this.$authStore.access_token
            },
            handler: (...ags) => this.uploadImage(...ags),
            linkToImgUrl: '/api/v1/oss/replace_url',
            linkToImgFormat: res => `{ "data": ${res}, "code": 0 }`
          },
          after: () => {
            this.$vditor = vditor;
            resolve(vditor);
            this.$emit('loaded', vditor);
          },
          input: value => this.$emit('input', value),
          focus: value => this.$emit('focus', value),
          blur: value => this.$emit('blur', value),
        });
        Object.keys(vditor.vditor).map(item => {
          Object.defineProperty(vditor, item, {
            get: () => vditor.vditor[item]
          });
        });
      });
    }

    async uploadImage(files) {
      try {
        const url = await uploadFile(files[0]);
        this.$vditor.focus();
        this.$vditor.insertValue(`![](${url})`);
      } catch (e) {
        this.$vditor.tip.show(e.message);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .editor {
    height: 100%;
    min-height: 300px;
  }

  .only-read {
    overflow: hidden;
  }

  /deep/ .vditor-reset {
    ul, ol {
      padding-left: 0.1em;

      > li:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }

    img {
      max-width: 100%;
    }

    h1, h2, h3, h4, h5, h6, strong {
      font-weight: bold;
    }

    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 14px;
    }

    code:not(.hljs):not(.highlight-chroma) {
      color: #87314e;
      background-color: rgba(135, 49, 78, 0.08);
    }
  }
</style>
