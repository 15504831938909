import { render, staticRenderFns } from "./author-edit.vue?vue&type=template&id=7dbb7504&scoped=true&"
import script from "./author-edit.vue?vue&type=script&lang=js&"
export * from "./author-edit.vue?vue&type=script&lang=js&"
import style0 from "./author-edit.vue?vue&type=style&index=0&id=7dbb7504&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.5_ratplj7weczqftnv2bjuzz474e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dbb7504",
  null
  
)

export default component.exports