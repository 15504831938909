import { render, staticRenderFns } from "./post-detail.vue?vue&type=template&id=bd227d62&scoped=true&"
import script from "./post-detail.vue?vue&type=script&lang=js&"
export * from "./post-detail.vue?vue&type=script&lang=js&"
import style0 from "./post-detail.vue?vue&type=style&index=0&id=bd227d62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.5_ratplj7weczqftnv2bjuzz474e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd227d62",
  null
  
)

export default component.exports