import { Vue } from 'vue-property-decorator';

import {
  Form,
  FormItem,
  Menu,
  Submenu,
  MenuItem,
  Row,
  Col,
  Loading,
  Message,
  MessageBox,
  Notification,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  Carousel,
  CarouselItem,
  Tabs,
  TabPane,
  Select,
  Option,
  Input,
  Pagination as ElPagination,
  Popover,
  Dialog,
  Button,
  Autocomplete,
  Breadcrumb,
  Popconfirm,
  Upload,
  Switch,
  Backtop,
  BreadcrumbItem
} from 'element-ui';

Vue.use(ElPagination)
  .use(Upload)
  .use(Form)
  .use(FormItem)
  .use(Menu)
  .use(MenuItem)
  .use(Submenu)
  .use(Input)
  .use(Select)
  .use(Option)
  .use(Carousel)
  .use(CarouselItem)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Tabs)
  .use(TabPane)
  .use(Row)
  .use(Col)
  .use(Dialog)
  .use(Button)
  .use(Popover)
  .use(Autocomplete)
  .use(Breadcrumb)
  .use(Popconfirm)
  .use(Switch)
  .use(Backtop)
  .use(Loading.directive)
  .use(BreadcrumbItem);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
